import React, { useState } from 'react';
import { graphql, Link } from 'gatsby'
import ReactMarkdown from 'react-markdown/with-html'
import styled from "styled-components"
import Layout from '../layouts'
  

const DataFact = ({ data }) => {
  return (
    <Layout>
      <Container style={{Container}}>
        <div>
          {data.contentfulDataFact.slug}
        </div>
      </Container>      
    </Layout>
  )
}


const Container = styled.div` 

  background: ${props => props.theme.background};  
  margin: 0;
  padding-bottom: 20px;

  li, pre, code {
    font-size: 22px;
    color: ${props => props.theme.text};
    opacity: .87;
    font-family: sans-serif;
  }
  h1, h2, h3, h4 {
    color: ${props => props.theme.text};
    opacity: .87;
    text-align: center;
    padding: 0px 25px 0px 25px;
    font-size: 28px;
    font-family: sans-serif;
    margin-bottom: 10px;
    letter-spacing: 1.2px;
    line-height:125%;
    word-spacing: 6px;
  }
  pre {
    color: ${props => props.theme.text};
    opacity: .87;
    margin: 0px 0px 0px 0px;
    font-family: sans-serif;
  }
  p, pre {
    font-size: 22px;
    margin-top: 25px;
    margin-bottom: 0px;
    padding: 0px 25px 0px 25px;
    color: ${props => props.theme.text};
    opacity: .87;
    text-indent: 0em;
    text-align: left;
    font-family: sans-serif;
    word-spacing: 6px;
    line-height:125%;
    letter-spacing: 1.2px;
  }
  img {
    max-width: 100%;
  }
  blockquote {
    background-color: antiquewhite;
  }

  .author {
    max-width: 800px;
    margin: 0 auto;
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    padding: 10px 50px 10px 25px;

    &:hover {
      opacity: 0.5;
      &:after {
        opacity: 1;
      }
    }

    span {
      color: ${props => props.theme.text};
      font-weight: bold;
      margin-left: 25px;
      font-size: 18px;
      opacity: .87;
      font-family: sans-serif;
      display: flex;
      align-self: center;
      justify-self: flex-start;
    }
    img {
      border-radius: 50%;
      display: flex;
      justify-self: flex-end;
      margin: 0;
    }

    a {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr;
    }
  }

  .summaryLevel {
    display: flex;
    flex: 1;
    align-items: flex-start;
    justify-content: center;
    list-style: none;
    border-radius: 10px;
    margin:0;
        
    li:nth-child(n+2) {
      padding-top: 8px;
    }

    li  {
      width: 70px;
      height: 90px;
      margin: 0 10px 0 10px;
      justify-content: center;
      display: flex;
    }

    input[type="checkbox"] {
      display: none;
    }

    img {
      max-width: 50px;
      max-height: 50px;
    }
    .icon3 {
      max-width: 70px;
      max-height: 70px;
      margin: 0;
    }
    
  }

  .body{
    max-width: 800px;
    margin: 0 auto;
  }
`

export default DataFact;

export const pageQuery = graphql`
  query datafactQuery($slug: String!) {
    contentfulDataFact(slug: {eq: $slug}) {
      slug
    }
  }
`